import axios from 'axios';
import { API_URLS } from './apiURL';
const api = axios.create({
    baseURL: API_URLS.auth,
    withCredentials: true,  // required to handle cookies cross-origin
  });
// Interceptor to handle token expiration and refresh token logic
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Automatic token refresh is handled server-side via HttpOnly cookies,
    // so no client-side logic needed to update tokens.
    if (error.response && !originalRequest._retry && error.response.status !== 401 ) {
        originalRequest._retry = true;
        await api.post('/refresh');
        return api(originalRequest);
      }

    return Promise.reject(error);
  }
);
export const registerUser = async (email, password) => {
    const response = await api.post(`/register`, {
        email,
        password,
    });
    return response.data;
};

export const loginUser = async (email, password) => {
    const response = await api.post(`/login`, {
        email,
        password,
    });
    return response.data;
};

export const getUserStatus = async () => {
    try {
        const response = await api.get(`/status`, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        if (error.response ) {
            return { isAuthenticated: false };
        }
        throw error;
    }
};

export const isAuthenticated = async () => {
    try {
        const response = await api.get(`/status`, {
            withCredentials: true,
        });
        return response.data.isAuthenticated;
    } catch (error) {
        if (error.response) {
            return false;
        }
        throw error;
    }
};