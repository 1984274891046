import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { Box, Tooltip, TextField, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, ListItemIcon, Divider, Container, Paper, Avatar } from '@mui/material';
import { Edit as EditIcon, MailOutline as MailIcon, Star as PremiumIcon, HelpOutline as HelpIcon, ArrowForward as ArrowIcon } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const UserJourneyTimeline = () => {
    const { user } = useAuth();
    const [events, setEvents] = useState([]);
    const [selectedDay, setSelectedDay] = useState(null);
    const [editorContent, setEditorContent] = useState({});
    const isPremium = user?.isPremium;
    const allowedDays = isPremium 
        ? ['Immediate', ...Array.from({ length: 21 }, (_, i) => `+${i + 1} days`)] 
        : ['Immediate', '+7 days'];

    const maxSlots = 5;
    const availableSlots = maxSlots - events.length;

    const handleEventSave = (day, subject, body) => {
        if (editorContent.isNew) {
            // New event
            setEvents(prev => [...prev, { day, subject, body }]);
        } else {
            // Update existing event
            const updatedEvents = events.map(e => e.day === day ? { day, subject, body } : e);
            setEvents(updatedEvents);
        }
        setSelectedDay(null);
        setEditorContent({});
    };

    return (
        <Container maxWidth="md">
            <Paper elevation={5} sx={{ mt: 4, p: 3, borderRadius: 2 }}>
                <Typography variant="h4" align="center" gutterBottom>Email Journey Timeline</Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>Click on the available slots to craft your perfect email journey for subscribers.</Typography>
                <Divider sx={{ my: 3 }} />
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h5">Member Added</Typography>
                    <Box display="flex" alignItems="center" justifyContent="space-between" p={2} mb={2} borderRadius={2} bgcolor="#f5f5f5" width="100%">
                    {events.map((event, idx) => (
        <Box key={event.day} sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Tooltip title={`Scheduled for ${event.day}`} arrow>
            <IconButton onClick={() => { setSelectedDay(idx + 1); setEditorContent(event); }}>
              <MailIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Typography variant="caption">{event.day}</Typography>
        </Box>
      ))}
      {Array.from({ length: availableSlots }).map((_, idx) => (
        <Box key={idx} sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Tooltip title="Available slot" arrow>
            <IconButton onClick={() => { setSelectedDay(events.length + 1); setEditorContent({ isNew: true }); }}>
              <EditIcon color="action" />
            </IconButton>
          </Tooltip>
        </Box>
      ))}
                    </Box>
                </Box>
                <EmailEventEditor 
                    eventData={editorContent}
                    open={!!selectedDay}
                    onSave={handleEventSave}
                    onClose={() => setSelectedDay(null)}
                    allowedDays={allowedDays}
                    existingDays={events.map(e => e.day)}
                    isPremium={isPremium}
                />
            </Paper>
        </Container>
    );
};

const EmailEventEditor = ({ eventData, open, onSave, onClose, allowedDays, existingDays, isPremium }) => {
    const [day, setDay] = useState(eventData.day || '');
    const [subject, setSubject] = useState(eventData.subject || '');
    const [body, setBody] = useState(eventData.body || '');

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
        ]
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Avatar sx={{ bgcolor: isPremium ? 'gold' : 'gray' }}>
                        {isPremium ? <PremiumIcon /> : <MailIcon />}
                    </Avatar>
                    <Typography variant="h6" sx={{ ml: 2 }}>
                        Edit Email Event
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Select
                    fullWidth
                    value={day}
                    onChange={e => setDay(e.target.value)}
                    sx={{ mb: 2 }}
                    disabled={existingDays.includes(day)}
                >
                    {allowedDays.map(d => (
                        <MenuItem value={d} key={d} disabled={existingDays.includes(d)}>
                            <ListItemIcon>
                                {d !== 'Immediate' && <PremiumIcon fontSize="small" />}
                            </ListItemIcon>
                            {d}
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    label="Subject"
                    fullWidth
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                    variant="outlined"
                    sx={{ mb: 2 }}
                />
                <ReactQuill value={body} onChange={setBody} modules={modules} theme="snow" />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => onSave(day, subject, body)}
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserJourneyTimeline;
