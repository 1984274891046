import axios from 'axios';
import { API_URLS } from './apiURL';
const api = axios.create({
  baseURL: API_URLS.base,
  withCredentials: true,  // required to handle cookies cross-origin
});
// Interceptor to handle token expiration and refresh token logic
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Automatic token refresh is handled server-side via HttpOnly cookies,
    // so no client-side logic needed to update tokens.
    if (error.response && !originalRequest._retry && error.response.status !== 401 ) {
        originalRequest._retry = true;
        await api.post('/refresh');
        return api(originalRequest);
      }

    return Promise.reject(error);
  }
);

export const validate = async (formData) => {
    console.log(formData);
  // As HttpOnly cookies are used, no need to explicitly set the Authorization header.
  const response = await api.post('/onboarding/validate-ghost-credentials', formData);

  return response.data;
};
export const updateCredentials = async (formData) => {
    console.log(formData);
  // As HttpOnly cookies are used, no need to explicitly set the Authorization header.
  const response = await api.post('/onboarding/update-ghost-credentials', formData);

  return response.data;
};