import React, { useEffect, useState } from 'react';
import './Analytics.css';
import { FaCode } from 'react-icons/fa';
import { MdSettings } from 'react-icons/md';
import { useAuth } from './AuthContext';
import { getAnalytics } from '../api/analytics';
import AnalyticsCard from './AnalyticsCard';
import SortableTable from './SortableTable';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ArticleComponent from './ArticleComponent';
const Analytics = () => {
    const { user, loading } = useAuth();
    const [analyticsData, setAnalyticsData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [refreshRate, setRefreshRate] = useState(user.isPremium ? 600000 : 3600000); // Default to 10 mins for premium, 60 mins for free
    const [refreshIntervalId, setRefreshIntervalId] = useState(null);
    const [showSettings, setShowSettings] = useState(false);
    // Function to toggle the modal
    const toggleModal = () => setShowModal(!showModal);
   
      
    useEffect(() => {
        const fetchAnalyticsData = async () => {
            try {
              const res = await getAnalytics(user.id);
              const transformedData = res.analytics.map(item => ({
                _id: item.url,
                pageTitle: item.general.pageTitle,
                avgScrollDepth: item.general.avgScrollDepth,
                avgTimeSpent: item.general.avgTimeSpent,
                avgEngagement: item.general.avgEngagement,
                pageviews: item.general.totalPageViews,
                clicks: item.general.totalClicks,
                uniqueVisitorsCount: item.general.uniqueVisitorsCount,
                bounceRate: item.general.bounceRate,
                hourlyData: item.hourly,
                dailyData: item.daily,
                commonPaths: item.commonPaths,
                referrerCounts: item.general.referrerCounts,
                uniqueReferrers: item.general.uniqueReferrers,
                // Add any other data you want from "item" as per your requirements
            }));
              setAnalyticsData(transformedData);
            } catch (err) {
              console.error('Error fetching analytics:', err);
            }
          };

        if (!loading && user) {
            fetchAnalyticsData();


            // Clear any existing intervals
            if (refreshIntervalId) {
                clearInterval(refreshIntervalId);
            }

            // Set up the auto-refresh
            const intervalId = setInterval(fetchAnalyticsData, refreshRate);
            setRefreshIntervalId(intervalId);
        }

        return () => {
            // Cleanup
            if (refreshIntervalId) {
                clearInterval(refreshIntervalId);
            }
        };
    }, [user, loading, refreshRate]);

    const renderNoData = () => (
        <div>No Data</div>
    );
    const toggleSettings = () => setShowSettings(!showSettings);
    const msToMinutes = (ms) => (ms / 1000 / 60).toFixed(2);
    const handleRefreshRateChange = (newRate) => {
        setRefreshRate(newRate);
        toggleSettings();
        // Ideally, persist this choice for the user on the server
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(`<script src="${process.env.REACT_APP_API_BASE_URL}/analytics/${user.id}/trackLoader.js"></script>`);
            setIsCopied(true);

            // Hide the "Copied!" message after 3 seconds
            setTimeout(() => { setIsCopied(false); setShowModal(false) }, 3000);
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }

    };
    return (
        <div className="analytics-container">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2>Analytics Dashboard</h2>
                <div className="flex items-center space-x-4">
                <button onClick={toggleSettings} className="bg-green-500 text-white w-full mt-4 px-4 py-2 rounded">
                <MdSettings />
            </button>
                    <button onClick={toggleModal} className="bg-blue-500 text-white w-full mt-4 px-4 py-2 rounded">
                        <FaCode />
                    </button>
            

            {showSettings && (
  <div className="modal-overlay">
    <div className="modal">
      <div className="modal-content">
        <div className="settings-dropdown">
          <h3>Refresh Rate</h3>
          {user.isPremium ? (
            <ToggleButtonGroup
              exclusive
              size="small"
              value={refreshRate}
              onChange={(event, newRate) => {
                if (newRate !== null) {
                  handleRefreshRateChange(newRate);
                }
              }}
            >
              <ToggleButton size="small" value={300000}>5 mins</ToggleButton>
              <ToggleButton size="small" value={600000}>10 mins</ToggleButton>
              <ToggleButton size="small" value={900000}>15 mins</ToggleButton>
              <ToggleButton size="small" value={1800000}>30 mins</ToggleButton>
              <ToggleButton size="small" value={3600000}>60 mins</ToggleButton>
            </ToggleButtonGroup>
          ) : (
            <p>Upgrade to premium to change the refresh rate.</p>
          )}
        </div>
      </div>
    </div>
  </div>
)}

                </div>
            </div>
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="modal-content">
                            <h3>Copy this script into your Ghost Code Injection in the Footer</h3>
                            <code className="script-tag">
                                {`<script src="${process.env.REACT_APP_API_BASE_URL}/analytics/${user.id}/track.js"></script>`}
                            </code>
                            <div className="integration-section">
                                <button onClick={copyToClipboard} className="copy-button">
                                    {isCopied ? 'Copied!' : 'Copy to Clipboard'}
                                </button>
                                <button onClick={toggleModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {analyticsData && analyticsData.length  > 0 ? (
                <>
                    <div className="chart-row">
                        <AnalyticsCard title="Most Popular Articles" className="chart-card">
                        <Bar 
                        data={{
                            labels: analyticsData.map((item) => 
                                item.pageTitle.length > 15 ? `${item.pageTitle.substring(0, 12)}...` : item.pageTitle
                            ),
                            datasets: [
                              {
                                label: 'Views',
                                data: analyticsData.map((item) => item.pageviews),
                                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                                borderColor: 'rgba(75, 192, 192, 1)',
                                borderWidth: 1,
                              },
                              {
                                label: 'Average Time Spent (minutes)',
                                data: analyticsData.map((item) => msToMinutes(item.avgTimeSpent)),
                                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                                borderColor: 'rgba(255, 99, 132, 1)',
                                borderWidth: 1,
                              }
                            ]
                          }}

                          options={{
                            scales: {
                              x: {
                                reverse: true,
                                beginAtZero: true
                              },
                              y: {
                                beginAtZero: true,
                              },
                            },
                          }}
                          />
                        </AnalyticsCard>

                        <AnalyticsCard title="Most Scrolled Articles" className="chart-card">
                            <Bar data={{
                            labels: analyticsData.map((item) => 
                            item.pageTitle.length > 15 ? `${item.pageTitle.substring(0, 12)}...` : item.pageTitle
                        ),
                            datasets: [
                              {
                                label: 'Average Scroll Depth (%)',
                                data: analyticsData.map((item) => item.avgScrollDepth),
                                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                                borderColor: 'rgba(153, 102, 255, 1)',
                                borderWidth: 1,
                              }
                              ,
                    
                              {
                                label: 'Engagement',
                                data: analyticsData.map((item) => item.avgEngagement),
                                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                                borderColor: 'rgba(255, 99, 132, 1)',
                                borderWidth: 1,
                              }
                            ]
                          }}

                          options={{
                            scales: {
                              x: {
                                reverse: true,
                                beginAtZero: true
                              },
                              y: {
                                beginAtZero: true,
                              },
                            },
                          }}/>
                        </AnalyticsCard>
                    </div>
                    <AnalyticsCard title="Article Details" className="table-card">

                        {analyticsData.map((item) => (
                            <ArticleComponent data={item} />
                        ))}
                    </AnalyticsCard>
                </>
            ) : (
                renderNoData()
            )}

        </div>
    );
}
export default Analytics;