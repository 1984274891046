import React from 'react';
import PropTypes from 'prop-types';

const AnalyticsCard = ({ title, children }) => (
  <div className="analytics-card">
    <h3>{title}</h3>
    {children}
  </div>
);

AnalyticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AnalyticsCard;
