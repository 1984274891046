import React, { createContext, useContext, useState, useEffect} from 'react';
import { getUserStatus } from '../api/auth';
const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);  // Add loading state
    useEffect(() => {
      const fetchUserStatus = async () => {
        try {
            const status = await getUserStatus();
        if (status.isAuthenticated) {
          setUser(status);
        }
        setLoading(false)
        } catch (error) {
            console.log(error);
            setUser(null);
            setLoading(false);
        }
        
      };
  
      fetchUserStatus();
    }, []);
  
    const login = (newUser) => {
      setUser(newUser);
    };
  
    const logout = () => {
      setUser(null);
    };
  
    // Initialize user here. Maybe you want to fetch it from an API or from local storage.
  
    return (
        <AuthContext.Provider value={{ user, setUser, login, logout, loading }}>
        {children}
      </AuthContext.Provider>
    );
  };