import React, { useState } from 'react';
import { validate } from '../api/onboarding';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
const Onboarding = () => {
    const navigate = useNavigate();
    const [selectedPlan] = useState();
  const [formData, setFormData] = useState({
    url: '',
    contentToken: '',
    adminToken: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    console.log(selectedPlan);
    const fullFormData = { ...formData, selectedPlan }; 
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await validate(fullFormData);
      if (response.success) {
        toast.success('Successfully validated credentials! Redirecting...');
        navigate('/dashboard');
      }
    } catch (error) {
      toast.error('Validation failed.');
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <ToastContainer />
      <div className="grid grid-cols-3 gap-4 w-3/4 bg-white p-8 rounded-lg shadow-lg">
        <div className="col-span-1">
          <h2 className="text-2xl font-bold">Get Your Ghost Credentials:</h2>
          <ol className="list-decimal list-inside">
            <li>Navigate to Ghost Admin</li>
            <li>Go to the Integrations Screen</li>
            <li>Click on 'Add custom integration'</li>
            <li>Name your Integration Ghostlytics</li>
            <li>Copy the API URL, Content and Admin API Keys</li>
          </ol>
          <a className="text-blue-500 hover:underline" href="https://ghost.org/integrations/custom-integrations/" target="_blank" rel="noopener noreferrer">Learn More</a>
        </div>
        <div className="col-span-2">
          <form onSubmit={handleSubmit} className="space-y-4">
            <h2 className="text-2xl font-bold">Connect to Ghost</h2>
            <p className="text-sm text-gray-500">To connect to Ghost, you need the following credentials:</p>
            {/* URL Field */}
            <div className="space-y-2">
              <div className="flex items-center space-x-4">
                <label htmlFor="url" className="w-1/3 text-lg font-medium">API URL:</label>
                <input
                  id="url"
                  name="url"
                  type="text"
                  value={formData.url}
                  onChange={handleChange}
                  className="w-2/3 p-2 border rounded"
                  placeholder="Your API URL here"
                  required
                />
              </div>
              <p className="text-xs text-gray-500">The API URL enables us to locate your Ghost blog. You can revoke our access anytime from your Ghost admin.</p>
            </div>

            {/* Content Token Field */}
            <div className="space-y-2">
              <div className="flex items-center space-x-4">
                <label htmlFor="contentToken" className="w-1/3 text-lg font-medium">Content Token:</label>
                <input
                  id="contentToken"
                  name="contentToken"
                  type="password"
                  value={formData.contentToken}
                  onChange={handleChange}
                  className="w-2/3 p-2 border rounded"
                  placeholder="Your Content Token here"
                  required
                />
              </div>
              <p className="text-xs text-gray-500">The content token provides read-only access to your Ghost content for display. You can revoke this token anytime.</p>
            </div>

            {/* Admin Token Field */}
            <div className="space-y-2">
              <div className="flex items-center space-x-4">
                <label htmlFor="adminToken" className="w-1/3 text-lg font-medium">Admin Token:</label>
                <input
                  id="adminToken"
                  name="adminToken"
                  type="password"
                  value={formData.adminToken}
                  onChange={handleChange}
                  className="w-2/3 p-2 border rounded"
                  placeholder="Your Admin Token here"
                  required
                />
              </div>
              <p className="text-xs text-gray-500">The admin token allows for content management features like posting or editing articles. This token can also be revoked anytime.</p>
            </div>
            <p className="text-xs text-gray-500">
              Note: All data is encrypted and only decrypted during its use. We don't store unencrypted data. We also provide a usage log for transparency.
            </p>
            <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">
              Submit
            </button>
          </form>
          {isLoading && <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
