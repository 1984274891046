// DashboardLayout.js
import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

const DashboardLayout = ({ children }) => (
  <div className="flex h-screen bg-gray-100">
    <Sidebar />
    <div className="flex-1 flex flex-col">
      <Header />
      <div className="flex-1 overflow-y-auto p-5">
        {children}
      </div>
    </div>
  </div>
);

export default DashboardLayout;
