import axios from 'axios';
import { API_URLS } from './apiURL';
const api = axios.create({
  baseURL: API_URLS.metrics,
  withCredentials: true,  // required to handle cookies cross-origin
});
const base = axios.create({
    baseURL: API_URLS.auth,
    withCredentials: true,  // required to handle cookies cross-origin
  });

// Interceptor to handle token expiration and refresh token logic
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Automatic token refresh is handled server-side via HttpOnly cookies,
    // so no client-side logic needed to update tokens.
    if (error.response && !originalRequest._retry && error.response.status !== 401) {
        originalRequest._retry = true;
        await base.post('/refresh');
        return api(originalRequest);
      }

    return Promise.reject(error);
  }
);

export const getAnalytics = async (userId) => {
  // As HttpOnly cookies are used, no need to explicitly set the Authorization header.
  const response = await api.get(`/display`);
  return response.data;
};
