import React, { memo } from 'react';
import { 
    Grid, Accordion, AccordionSummary, AccordionDetails, Typography, LinearProgress, Box, Divider, Tooltip 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Bar, Doughnut } from 'react-chartjs-2';
import SortableTable from './SortableTable';

const getColorForTime = (time) => {
  return time > 2 ? 'primary.light' : 'secondary.light';
};

const getColorForEngagement = (score) => {
  return score > 50 ? 'primary.light' : 'secondary.light';
};

const getDaysInMonth = () => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};


const ArticleComponent = ({ data }) => {
  const timeInMinutes = (data.avgTimeSpent / 60000).toFixed(2);
  const roundedEngagement = Math.floor(data.avgEngagement);
  const allHoursArray = Array.from({ length: 24 }, (_, i) => i);
  const defaultHourlyPageViews = new Array(24).fill(0); 
const mapDataToHours = (dataMetric) => {
    return allHoursArray.map(hour => {
        const hourData = data.hourlyData.find(d => d._id.hour === hour);
        return hourData ? hourData[dataMetric] : 0;
    });
};

const hourlyPageViewsData = {
    labels: allHoursArray.map(hour => `${hour}:00`),
    datasets: [{
        label: 'Hourly Page Views',
        data: mapDataToHours('hourlyPageViews'),
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1
    }]
};

const hourlyInteractionsData = {
    labels: allHoursArray.map(hour => `${hour}:00`),
    datasets: [{
        label: 'Hourly Interactions',
        data: mapDataToHours('hourlyInteractions'),
        backgroundColor: 'rgba(255, 159, 64, 0.4)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1
    }]
};

const hourlyEngagementScoreData = {
    labels: allHoursArray.map(hour => `${hour}:00`),
    datasets: [{
        label: 'Hourly Engagement Score',
        data: mapDataToHours('hourlyEngagementScore'),
        backgroundColor: 'rgba(255, 99, 132, 0.4)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1
    }]
};

  const totalDays = getDaysInMonth();
const allDaysArray = Array.from({ length: totalDays }, (_, i) => i + 1);

const mapDataToDays = (dataMetric) => {
    return allDaysArray.map(day => {
        const dayData = data.dailyData.find(d => d._id.day === day);
        return dayData ? dayData[dataMetric] : 0;
    });
};

const dailyPageViewsData = {
    labels: allDaysArray.map(day => `Day ${day}`),
    datasets: [{
        label: 'Daily Page Views',
        data: mapDataToDays('dailyPageViews'),
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1
    }]
};

const dailyInteractionsData = {
    labels: allDaysArray.map(day => `Day ${day}`),
    datasets: [{
        label: 'Daily Interactions',
        data: mapDataToDays('dailyInteractions'),
        backgroundColor: 'rgba(255, 159, 64, 0.4)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1
    }]
};

const dailyEngagementScoreData = {
    labels: allDaysArray.map(day => `Day ${day}`),
    datasets: [{
        label: 'Daily Engagement Score',
        data: mapDataToDays('dailyEngagementScore'),
        backgroundColor: 'rgba(255, 99, 132, 0.4)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1
    }]
};

const chartOptions = {
    scales: {
        y: {
            beginAtZero: true,
            max: Math.max(
                ...data.dailyData.map(d => d.dailyPageViews),
                ...data.dailyData.map(d => d.dailyInteractions),
                ...data.dailyData.map(d => d.dailyEngagementScore)
            ) + 5
        }
    },
    plugins: {
        legend: {
            display: true
        }
    },
    maintainAspectRatio: true
};
  

  const bounceRateData = {
    labels: ['Bounce', 'No Bounce'],
    datasets: [{
      data: [data.bounceRate, 100 - data.bounceRate],
      backgroundColor: ['#e57373', '#81c784'],
    }]
  };

  const referrersColumns = React.useMemo(
    () => [
      {
        Header: 'Referrer',
        accessor: 'referrer' // key from the data
      },
      {
        Header: 'Count',
        accessor: 'count' // key from the data
      }
    ],
    []
  );
  const referrersData = data.referrerCounts;

  return (
    <Accordion variant="outlined" sx={{ marginBottom: '10px', borderRadius: '10px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderRadius: '10px' }}>
      <Box flex={2}>
          <Typography variant="h6">{data.pageTitle}</Typography>
        </Box>
        <Box flex={1} pr={2}>
          <Typography variant="body2">Scroll Depth</Typography>
          <LinearProgress 
             variant="determinate" 
             value={data.avgScrollDepth} 
             sx={{ height: '8px', borderRadius: '5px', backgroundColor: '#eaeaea' }} 
          />
        </Box>
        <Box 
           flex={1} 
           pr={2} 
           display="flex" 
           flexDirection="column" 
           alignItems="center" 
           justifyContent="center" 
           bgcolor={getColorForTime(data.avgTimeSpent)} 
           borderRadius="10px"
           boxShadow="0px 2px 5px rgba(0, 0, 0, 0.1)"
        >
          <Typography variant="body2">Time Spent</Typography>
          <Typography variant="h6">{timeInMinutes} mins</Typography>
        </Box>
        <Box 
           flex={1} 
           display="flex" 
           flexDirection="column" 
           alignItems="center" 
           justifyContent="center" 
           bgcolor={getColorForEngagement(roundedEngagement)} 
           borderRadius="10px"
           boxShadow="0px 2px 5px rgba(0, 0, 0, 0.1)"
        >
          <Typography variant="body2">Engagement Score</Typography>
          <Typography variant="h6">{roundedEngagement}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          {/* Other metrics */}
          <Grid item xs={3}>
            <Typography variant="body2">Unique Visitors</Typography>
            <Typography variant="h6">{data.uniqueVisitorsCount}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2">Page Views</Typography>
            <Typography variant="h6">{data.pageviews}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2">Clicks</Typography>
            <Typography variant="h6">{data.clicks}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
    <Typography variant="h6" gutterBottom>Hourly Page Views</Typography>
    <Box width="100%">
        <Bar data={hourlyPageViewsData} options={{
        scales: {
            y: {
                beginAtZero: true,
                max: Math.max(...hourlyPageViewsData.datasets[0].data) + 10 // or any buffer you want
            }
        }
    }} />
    </Box>
</Grid>

<Grid item xs={12} md={4}>
    <Typography variant="h6" gutterBottom>Hourly Interactions</Typography>
    <Box  width="100%">
    <Bar 
    data={hourlyInteractionsData}
    options={{
        scales: {
            y: {
                beginAtZero: true,
                max: Math.max(...hourlyInteractionsData.datasets[0].data) + 10
            }
        }
    }}
/>
    </Box>
</Grid>

<Grid item xs={12} md={4}>
    <Typography variant="h6" gutterBottom>Hourly Engagement Score</Typography>
    <Box width="100%">
    <Bar 
    data={hourlyEngagementScoreData}
    options={{
        scales: {
            y: {
                beginAtZero: true,
                max: Math.max(...hourlyEngagementScoreData.datasets[0].data) + 10
            }
        }
    }}
/>
    </Box>
</Grid>
          <Grid item xs={12} md={4}>
    <Typography variant="h6" gutterBottom>Daily Page Views</Typography>
    <Box  width="100%">
        <Bar data={dailyPageViewsData} options={chartOptions} />
    </Box>
</Grid>

<Grid item xs={12} md={4}>
    <Typography variant="h6" gutterBottom>Daily Interactions</Typography>
    <Box  width="100%">
        <Bar data={dailyInteractionsData} options={chartOptions} />
    </Box>
</Grid>

<Grid item xs={12} md={4}>
    <Typography variant="h6" gutterBottom>Daily Engagement Score</Typography>
    <Box  width="100%">
        <Bar data={dailyEngagementScoreData} options={chartOptions} />
    </Box>
</Grid>
<Grid item xs={8}>
<Typography variant="body2">Referrers</Typography>
<Box height={250} width="100%" overflow="auto">
          <MemoizedSortableTable columns={referrersColumns} data={referrersData} />
        </Box>
          </Grid>
          {/* Bounce Rate Doughnut Chart */}
          <Grid item xs={4}>
            <Typography variant="body2">Bounce Rate</Typography>
            <Box height={250} width="100%">
              <Doughnut data={bounceRateData} options={{ responsive: true, maintainAspectRatio: false }} />
            </Box>
          </Grid>
        
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
const MemoizedSortableTable = memo(SortableTable);
export default ArticleComponent;

