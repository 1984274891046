import React from 'react';
import { useAuth } from '../components/AuthContext';

const Header = () => {
  const { user } = useAuth();
  if (!user) return null; 
  const remainingDays = Math.floor((new Date(user.trialEnds) - new Date()) / (1000 * 60 * 60 * 24));

  return (
    <header className="bg-white p-4 border-b border-gray-200 flex justify-between items-center">
      <h1 className="header-title">Ghostlytics</h1>
    
      {user.subscriptionStatus == "trialing" && <span>{remainingDays} Days remaining in Free Trial</span>}
    </header>
  );
};

export default Header;
