import React, { useState } from 'react';
import { registerUser, loginUser, isAuthenticated } from '../api/auth';  // Assume isAuthenticated is a function that checks if user is authenticated
import './AuthModal.css';  // Don't forget to import your styles
import { useNavigate } from 'react-router-dom';
import {useAuth} from './AuthContext';

const AuthModal = ({ onClose }) => {
  const [isRegister, setIsRegister] = useState(true); // true = register, false = login
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();
  let userData;
  const handleAuth = async () => {
    try {
      if (isRegister) {
        userData = await registerUser(email, password);
      } else {
        userData = await loginUser(email, password);
      }
      console.log(email, password);
      console.log(userData);

      // Check if the user is authenticated
      const authStatus = await isAuthenticated();
      console.log(authStatus);
      if (authStatus) {
        console.log(userData);
        login(userData);
        onClose();
          if (!userData.isOnboarded) {
              navigate('/onboarding');
          } else if (new Date(userData.trialEnds) < new Date() && !userData.isPremium) {
              navigate('/subscribe');
          } else {
              navigate('/dashboard');
          }
        
      } else {
        setError('Authentication failed. Please try again.');
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        setError(err.response.data.message || 'An error occurred.');
      } else if (err.request) {
        setError('No response received from server.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
  };

  return (
    <div className="auth-modal">
      <div className="auth-modal-card">
        <button className="auth-close-button" onClick={onClose}>×</button>
        <div className="auth-toggle-buttons">
          <button className={isRegister ? 'active' : ''} onClick={() => setIsRegister(true)}>Register</button>
          <button className={!isRegister ? 'active' : ''} onClick={() => setIsRegister(false)}>Login</button>
        </div>
        {error && <div className="auth-error-message">{error}</div>}
        <div className="auth-form">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="auth-submit-button" onClick={handleAuth}>
            {isRegister ? 'Register' : 'Login'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
