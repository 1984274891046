import React from 'react';

const CRM = () => {
  return (
    <div>
      <h2>CRM Add-Ons</h2>
      <p>Mock table for contact management</p>
      <p>Mock section for interaction tracking</p>
    </div>
  );
};

export default CRM;
