import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import Joyride, { STATUS } from 'react-joyride';
import { FaChartLine, FaCogs, FaEnvelope, FaStar, FaUserCog  } from 'react-icons/fa';

const Sidebar = () => {
  const { user } = useAuth();
  const [isTourFinished, setIsTourFinished] = useState(false);
  const isPremiumOrTrialActive = user.isPremium || new Date(user.trialEnds) > new Date();

  useEffect(() => {
    const hasTourFinished = localStorage.getItem('tourFinished') === 'true';
    setIsTourFinished(hasTourFinished);
  }, []);

  const steps = [
    {
      target: '.analytics-link',
      content: 'Check your analytics here.',
    },
    {
      target: '.crm-link',
      content: 'Manage your CRM add-ons here.',
    },
    {
      target: '.drip-link',
      content: 'Manage your drip email and setup retention'
    },
    // ... add more steps as needed
  ];

  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      localStorage.setItem('tourFinished', 'true');
      setIsTourFinished(true);
    }
  };

  if (!user) return null;

  return (
    <aside className="w-64 bg-white p-4 border-r border-gray-200">
      {!isTourFinished && (
        <Joyride 
          steps={steps} 
          continuous={true} 
          showSkipButton={true} 
          callback={handleJoyrideCallback}
        />
      )}
      <nav className="text-gray-700">
      <ul className="space-y-4">
          <li className="analytics-link flex items-center space-x-2 hover:bg-gray-100 p-2 rounded">
            <FaChartLine className="text-blue-500" />
            <Link className="analytics-link" to="/dashboard/analytics">Analytics Dashboard</Link>
          </li>
          {/**  
          <li className="crm-link flex items-center space-x-2 hover:bg-gray-100 p-2 rounded">
            <FaCogs className="text-green-500" />
            <Link className="crm-link" to="/dashboard/crm">CRM Add-Ons</Link>
          </li>
          */}
          <li className="drip-link flex items-center space-x-2 hover:bg-gray-100 p-2 rounded">
            <FaEnvelope className="text-yellow-500" />
            <Link className="drip-link" to="/dashboard/emails">Drip Email Campaigns</Link>
          </li>
          {/**  
          {isPremiumOrTrialActive && 
          <li className="flex items-center space-x-2 hover:bg-gray-100 p-2 rounded">
            <FaStar className="text-purple-500" />
            <Link to="/dashboard/premium-features">Premium Features</Link>
          </li>
          }
           */}
           <li className="flex items-center space-x-2 hover:bg-gray-100 p-2 rounded">
            <FaUserCog className="text-red-500" />
            <Link to="/dashboard/admin-settings">Newsletter Settings</Link>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;