import React from 'react';
import './Subscribe.css';  // Don't forget to import your styles
const Subscribe = () => {
    return (
      <div>
        Time to subscribe.
        {/* Your actual Onboarding JSX */}
      </div>
    );
  };
  
  export default Subscribe;