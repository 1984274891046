import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Onboarding from './components/Onboarding';
import Subscribe from './components/Subscribe';
import Analytics from './components/Analytics';
import CRM from './components/CRM';
import Emails from './components/Emails';
import NewsletterSettings from './components/NewsletterSettings';
import PremiumFeatures from './components/PremiumFeatures';
import { useProtectedElement } from './components/useProtectedElement';
import DashboardLayout from './components/DashboardLayout';
import { AuthContextProvider } from './components/AuthContext';
import './App.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';

function ProtectedDashboard() {
  return useProtectedElement(
    <DashboardLayout>
      <Routes>
        <Route path="/" element={<Analytics />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/crm" element={<CRM />} />
        <Route path="/emails" element={<Emails />} />
        <Route path="/premium-features" element={<PremiumFeatures />} />
        <Route path="/admin-settings" element={<NewsletterSettings />} />
      </Routes>
    </DashboardLayout>
  );
}

function App() {
  const theme = createTheme();
  console.log(theme);
  return (
    <ThemeProvider theme={theme}>
    <AuthContextProvider>
      <Router>
        <div className="App">
          <ToastContainer />
          <Routes>
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/dashboard/*" element={<ProtectedDashboard />} />
            <Route path="*" element={<LandingPage />} />
          </Routes>
        </div>
      </Router>
    </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
