import React, { useState } from 'react';
import { Stepper, Step, StepLabel, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/system';
import UserJourneyTimeline from './UserJourneyTimeline';
const EmailsContainer = styled('div')`
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Emails = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [journey, setJourney] = useState({
    selectedEvent: null,
    action: null,
    copySelf: false,
    emailSchedule: []
  });

  const steps = ['Select Event', 'Build Action', 'Compose Email'];

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
  const updateJourney = (newData) => setJourney({ ...journey, ...newData });

  return (
    <EmailsContainer>
      <h2>Drip Email Campaigns</h2>
      <UserJourneyTimeline />
    </EmailsContainer>
  );
};

export default Emails;
