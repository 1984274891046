import React, { useState, useEffect } from 'react';
import { FaDatabase, FaChartLine, FaEnvelope } from 'react-icons/fa';
import { Button, Card, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material';
import AuthModal from '../components/AuthModal';  // Make sure the path is correct
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';

const LandingPage = () => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('monthly');  // Fixed here
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      if (user) {
        if (user.isAuthenticated) {  // assuming getUserStatus returns null or false if not authenticated
          if (!user?.isOnboarded) {
            navigate('/onboarding');
          } else if (new Date(user.trialEnds) < new Date() && !user.isPremium) {
            navigate('/subscribe');
          } else {
            navigate('/dashboard');
          }
        }
      }
    }
  }, [user, loading, navigate]);

  return (
    <Container>
      <div style={{ textAlign: 'center', backgroundColor: '#f3f4f6', padding: '1rem' }}>
        <Typography variant="h2" sx={{ marginBottom: '1rem' }}>
          Ghostlytics
        </Typography>
        <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
          Supercharge Your Ghost Newsletter Experience
        </Typography>
        <div>
          <Button variant="outlined" color="primary" sx={{ marginRight: '0.5rem' }} onClick={() => setShowAuthModal(true)}>
            Login
          </Button>
          <Button variant="contained" color="primary" onClick={() => setShowAuthModal(true)}>
            Sign Up
          </Button>
        </div>
        <Grid container spacing={3} sx={{ marginTop: '2rem' }}>
          {[
            { icon: <FaDatabase />, title: 'CRM', desc: 'Consolidate your customer data and tailor your content accordingly.' },
            { icon: <FaChartLine />, title: 'Analytics', desc: 'Real-time, in-depth metrics for your articles and emails.' },
            { icon: <FaEnvelope />, title: 'Drip Emails', desc: 'Automate email sequences to engage users and convert them to premium plans.' },
          ].map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card>
                <CardContent className="flex flex-col items-center">
                  {item.icon}
                  <Typography variant="h6">
                    {item.title}
                  </Typography>
                  <Typography>
                    {item.desc}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <div style={{ marginTop: '2rem' }}>
          <Button variant="contained" color="secondary" size="large" onClick={() => setShowAuthModal(true)}>
            Start Free Trial
          </Button>
          {showAuthModal && <AuthModal onClose={() => setShowAuthModal(false)} />}
        </div>
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <Typography variant="h4" sx={{ marginBottom: '1rem' }}>
            Pricing
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            {[
              { title: 'Free', price: '$0/month', features: ['Free 30 day premium trial', 'Basic Analytics', 'CRM Features', 'Limited Drip Emails'] },
              { title: 'Premium', price: '$10/month', features: ['Free 30 day trial', 'Advanced Analytics', 'All CRM Features', 'Unlimited Drip Emails'] },
              { title: 'Yearly', price: '$90/year', features: ['Free 30 day trial', 'Advanced Analytics', 'All CRM Features', 'Unlimited Drip Emails'] },
            ].map((plan, index) => (
              <Grid item key={index}>
                <Card>
                  <CardHeader title={plan.title} subheader={plan.price} />
                  <CardContent>
                    <ul>
                      {plan.features.map((feature, i) => (
                        <li key={i}>{feature}</li>
                      ))}
                    </ul>
                  </CardContent>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setSelectedPlan(plan.title.toLowerCase());
                      setShowAuthModal(true);
                    }}
                  >
                    Get {plan.title}
                  </Button>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </Container>
  );
};

export default LandingPage;
